<script setup lang="ts">
const i18nScope = useLocale('header.main_menu');
const logo = useLogo();
const instancePath = `${useInstancePath()}admin/publications`;
const route = useRoute();
const renderNav = computed(() => {
  const name = route.name as string;
  return name.indexOf('publication') === 0 ||
  name.indexOf('organizations') === 0 ||
  name.indexOf('user') == 0 ||
  name.indexOf('contributors') == 0 ||
  name.indexOf('collections') == 0;
});
</script>
<template>
  <div class="l-header -sticky">
    <div class="l-header__logo">
      <a :href="instancePath">
        <img
          :src="logo.src"
          :alt="logo.alt"
        >
      </a>
    </div>

    <div class="l-header__test-tag">
      <HTooltip
        background="light"
        position="bottom"
        name="beta-help"
      >
        <template #target="{ id, describedBy, toggle }">
          <HTag
            :id="id"
            variant="secondary"
            usage="warning"
            class="w-max cursor-pointer"
            :aria-describedby="describedBy"
            @click="toggle"
          >
            {{ i18nScope('beta.label') }}
          </HTag>
        </template>
        <template #default>
          {{ i18nScope('beta.text') }}
        </template>
      </HTooltip>
    </div>

    <nav
      v-if="renderNav"
      class="l-header__nav"
    >
      <div class="l-header__item">
        <NuxtLink
          to="/publication-drafts"
          active-class="active"
          exact-active-class="active"
        >
          <HButton
            :label="i18nScope('home.title')"
            variant="nav"
            class="c-dropdown__button"
          />
        </NuxtLink>
      </div>

      <div class="l-header__item">
        <NuxtLink
          to="/publication-drafts/new"
          active-class="active"
          exact-active-class="active"
        >
          <HButton
            :label="i18nScope('publications.new.title')"
            icon="plus"
            variant="secondary"
            class="c-dropdown__button"
          />
        </NuxtLink>
      </div>
    </nav>
    <nav class="l-header__nav-right">
      <div
        v-if="renderNav"
        class="l-header__item --back"
      >
        <a :href="instancePath">
          <HButton
            :label="i18nScope('back_instance.title')"
            variant="tertiary"
            icon-right="corner-up-left"
          />
        </a>
      </div>
      <div class="l-header__actions">
        <div class="l-header__item --profil">
          <div class="c-dropdown -align-right">
            <PanelDropdown />
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
